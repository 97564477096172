import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Container, Row, Col } from "react-bootstrap"

const CustomContainer = ({ children, className, rounded, theme, ...props }) => (
  <Container
    {...props}
    className={classNames(
      className,
      "component-container",
      { light: theme === "light" },
      { rounded }
    )}
  >
    <Row noGutters>
      <Col>{children}</Col>
    </Row>
  </Container>
)

CustomContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  theme: PropTypes.string,
}

export default CustomContainer
