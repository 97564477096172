import React from "react"
import { Link } from "gatsby"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Newsletter from "../components/section-newsletter"
import BackgroundInterior from "../components/background/interior"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404: Not found"
      description="Sorry! You've hit a page that doesn't exist. We could not find the page you were looking for!"
    />
    <BackgroundInterior>
      <h1 className="text-center">Page Not Found</h1>
      <Container theme="light" rounded>
        <p>
          You've hit a page that doesn't exist. We could not find the page you
          were looking for!
        </p>
        <p>
          &ndash; <Link to="/">MicroIncome.com</Link>
        </p>
      </Container>
      <Newsletter />
    </BackgroundInterior>
  </Layout>
)

export default NotFoundPage
